import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import  { tableCellClasses } from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import MultiLineChart from './MultiLineChart';
import CompareChart from './CompareChart';
import { Link } from '@mui/material';
import {  TableContainer,  Paper } from '@mui/material';
import { format } from 'date-fns';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function Search(){
    const [tvSizeData, setTvSizeData] = useState(0);
    const [tvBrands, setTvBrands] = useState([]);
    
    const [availableSizes, setAvailableSizes] = useState([]);
    const [modelData,setModelData] = useState([]);

    const [selectedSize, setSelectedSize] = useState('all');
    const [selectedOs, setSelectedOs] = useState('all');
    const [selectedBrands, setSelectedBrands] = useState([]);


    const [searchResults, setSearchResults] = useState([]);
    const [comapreData,setCompareData] = useState([]);
   
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rowData, setSelectedRowData] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [openM, setOpenM] = React.useState(false);

  const tabOs = [
    "Android",
    "Android 9",
    "Android 11",
    "Google",
    "Basic",
    "LG",
    "Coolita",
    "Tizen",
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

    useEffect(() => {
        const fetchTVSizeData = async () => {
          try {
            const token = 'your_token_here';
            const response = await fetch(`https://waltontvrni.com/tv-compare-backend/api/getAllSizeTv`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzA1NTUwMTc3LCJleHAiOjE3MDU2MzY1Nzd9.5IyU3RNHqwO0YEGqdJjdNEwgXdVzdImF0MIW0PhQM24`,
              },
            });
    
            if (response.ok) {
              const data = await response.json();
              setTvSizeData(data.data);
    
              // Extract unique available sizes
              const availableSizesSet = new Set();
              data.data.forEach(table => {
                table.tvs.forEach(tv => {
                  if (tv.status === 'In Stock' && !availableSizesSet.has(tv.size)) {
                    availableSizesSet.add(tv.size);
                  }
                });
              });
    
              const availableSizesArray = Array.from(availableSizesSet);
              setAvailableSizes(availableSizesArray);
    
             
            } else {
              console.error('Failed to fetch TV size data');
            }
          } catch (error) {
            console.error('Error fetching TV size data', error);
          }
        };
        const fetchBrands = async () => {
            const headers = {
              'Content-Type': 'application/json',
              'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzA1NTUwMTc3LCJleHAiOjE3MDU2MzY1Nzd9.5IyU3RNHqwO0YEGqdJjdNEwgXdVzdImF0MIW0PhQM24`,
            };
      
            try {
                const response = await fetch(`https://waltontvrni.com/tv-compare-backend/api/getAllbrands`, {
                  method: 'GET',
                  headers: headers,
                });
          
                if (response.ok) {
                  const data = await response.json();
                  // Assuming the response is a list of brand names
                  console.log(data);
                  setTvBrands(data.data);
                } else {
                  // Handle error
                  console.error(`Failed to fetch brands. Status code: ${response.status}`);
                }
              } catch (error) {
                // Handle network error
                console.error('Error fetching brands:', error);
              }
            };

        fetchTVSizeData();
        fetchBrands();
        fetchData();


      }, [selectedSize,selectedOs,selectedBrands]);


      const fetchData = async () => {
        setSearchResults([]);
        try {
          const response = await fetch(`https://waltontvrni.com/tv-compare-backend/api/customSearch`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzA1NTUwMTc3LCJleHAiOjE3MDU2MzY1Nzd9.5IyU3RNHqwO0YEGqdJjdNEwgXdVzdImF0MIW0PhQM24',
            },
            body: JSON.stringify({
              size:selectedSize,
              os: selectedOs,
              brand: selectedBrands,
            }),
          });
  
          if (response.ok) {
            const data = await response.json();
            setSearchResults(data.data);

          } else {
            console.error('Failed to fetch search results');
          }
        } catch (error) {
          console.error('Error fetching search results', error);
        }
  };


      const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
       
      };

      const handleOsChange = (event) => {
        setSelectedOs(event.target.value);

      };


      const handleViewClick = (rowData) => {
        setModelData([]);
        setOpen(true);
        setSelectedRowData(rowData);
        const fetchData = async () =>{
          try {
            const response = await fetch(`https://waltontvrni.com/tv-compare-backend/api/model/data`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzA1NTUwMTc3LCJleHAiOjE3MDU2MzY1Nzd9.5IyU3RNHqwO0YEGqdJjdNEwgXdVzdImF0MIW0PhQM24',
              },
              body: JSON.stringify({
                model: rowData.model,
              }),
            });
    
            if (response.ok) {
              const data = await response.json();
              console.log(data.data);
              setModelData(data.data);
            } else {
              console.error('Failed to fetch search results');
            }
          } catch (error) {
            console.error('Error fetching search results', error);
          }
        };

        fetchData();

      };
    

      const handleClose = () => {
        setOpen(false);
      };

      const handleMClose = () => {
        setCompareData([]);
        setOpenM(false);
      };

      const handleSelectedBrandChange = (brand) => {
        if (selectedBrands.includes(brand)) {
          setSelectedBrands(selectedBrands.filter((selectedBrand) => selectedBrand !== brand));
        } else {
          setSelectedBrands([...selectedBrands, brand]);
        }

      };


      const handleCompareSubmit = async () => {
        setCompareData([]);
        setOpenM(true);
      
        const fetchData = async (model) => {
          try {
            const response = await fetch(`https://waltontvrni.com/tv-compare-backend/api/model/data`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzA1NTUwMTc3LCJleHAiOjE3MDU2MzY1Nzd9.5IyU3RNHqwO0YEGqdJjdNEwgXdVzdImF0MIW0PhQM24',
              },
              body: JSON.stringify({
                model: model,
              }),
            });
      
            if (response.ok) {
              const data = await response.json();
              return data.data;
            } else {
              console.error('Failed to fetch search results');
              return null;
            }
          } catch (error) {
            console.error('Error fetching search results', error);
            return null;
          }
        };
      
        try {
          const promises = searchResults.map(item => fetchData(item.model));
          const results = await Promise.all(promises);
      
          // Filter out null values (failed requests) before updating the state
          const validResults = results.filter(result => result !== null);
        
          setCompareData([...comapreData, ...validResults]);
          console.log(comapreData);
        } catch (error) {
          console.error('Error comparing data', error);
        }
      };


   

      const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
          direction = 'desc';
        }
        setSortConfig({ key, direction });
      };
    
      const dynamicSort = (a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      };
    
      const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(0); // Reset page to the first when the search term changes
      };
    
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    
      const filteredResults = searchResults.filter((result) => {
        return (
          result.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
          result.os.toLowerCase().includes(searchTerm.toLowerCase()) ||
          result.size.toLowerCase().includes(searchTerm.toLowerCase()) ||
          result.price.toString().includes(searchTerm)
        );
      });
    
      const sortedAndFilteredResults = searchTerm
        ? filteredResults
        : searchResults.slice().sort(dynamicSort);


    
      return (
        
        <div>

          {/* Select list for available sizes */}
          <FormControl sx={{width:150, marginTop: 2, marginBottom: 2,  marginLeft:2 }}>
          <InputLabel id="size-dropdown-label">Select Size</InputLabel>
            <Select
            id='size-dropdown'
            labelId="size-dropdown-label"
              value={selectedSize}
              onChange={handleSizeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Select TV Size' }}
              label="Select Size"
            >
              <MenuItem value="" disabled>
                Select TV Size
              </MenuItem>
              <MenuItem value="all">Select All</MenuItem>

              {availableSizes.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
    
          {/* Select list for available OS */}
          <FormControl sx={{ width:150, marginTop: 2, marginBottom: 2 , marginLeft:2 }}>
          <InputLabel id="os-dropdown-label">Select OS</InputLabel>
            <Select
            id='os-dropdown'
              labelId="os-dropdown-label"
                value={selectedOs}
                onChange={handleOsChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Select OS' }}
                label="Select OS"
            >
              <MenuItem value="" disabled>
                Select OS
              </MenuItem>
              <MenuItem value="all">Select All</MenuItem>

              {tabOs.map((os) => (
                <MenuItem key={os} value={os}>
                  {os}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
    
          {/* Select list for available TV brands */}
          <FormControl sx={{ width:250, marginTop: 2, marginBottom: 2 ,marginRight:1, marginLeft:2 }} component="fieldset">
            <InputLabel id="brand-dropdown-label">Select Brand</InputLabel>
            <Select
              labelId="brand-dropdown-label"
              id="brand-dropdown"
              multiple
              value={selectedBrands || []}
              onChange={(event) => {
                const { value } = event.target;
                setSelectedBrands(value);
              }}
              label="Select Brand"
              renderValue={(selected) => selected.join(', ')}
            >
              <MenuItem value="all">
                <Checkbox
                  checked={selectedBrands.length === tvBrands.length}
                  onChange={() => {
                    if (selectedBrands.length === tvBrands.length) {
                      setSelectedBrands([]);
                    } else {
                      setSelectedBrands(tvBrands.map((brand) => brand.name));
                    }
                  }}
                />
                Select All
              </MenuItem>
              {tvBrands.map((brand) => (
                <MenuItem key={brand.name} value={brand.name}>
                  <Checkbox
                    checked={selectedBrands.includes(brand.name)}
                    onChange={() => handleSelectedBrandChange(brand.name)}
                  />
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
       

            <Button sx={{ marginTop: 2, marginBottom: 2 , marginLeft:2 }} variant="contained" color="primary" onClick={handleCompareSubmit}>
                    Compare
            </Button>


         <Grid sx={{marginLeft:2}}>
           {/* Search input */}
           <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchTermChange}
            />
         </Grid>

           
           
      {/* Display search results in a DataTable */}
      {sortedAndFilteredResults.length > 0 && (
        <>
          <Table sx={{marginTop:1}}>
            <TableHead>
              <StyledTableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'model'}
                    direction={sortConfig.key === 'model' ? sortConfig.direction : 'asc'}
                    onClick={() => handleSort('model')}
                  >
                    Model
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'brand'}
                    direction={sortConfig.key === 'brand' ? sortConfig.direction : 'asc'}
                    onClick={() => handleSort('brand')}
                  >
                    Brand
                  </TableSortLabel>
                </TableCell>

                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'os'}
                    direction={sortConfig.key === 'os' ? sortConfig.direction : 'asc'}
                    onClick={() => handleSort('os')}
                  >
                    OS
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'size'}
                    direction={sortConfig.key === 'size' ? sortConfig.direction : 'asc'}
                    onClick={() => handleSort('size')}
                  >
                    Size
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'price'}
                    direction={sortConfig.key === 'price' ? sortConfig.direction : 'asc'}
                    onClick={() => handleSort('price')}
                  >
                    Price
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'view'}
                    direction={sortConfig.key === 'view' ? sortConfig.direction : 'asc'}
                    onClick={() => handleSort('view')}
                  >
                    Details
                  </TableSortLabel>
                </TableCell>

                {/* Add more headers as needed */}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {sortedAndFilteredResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((result) => (
                <TableRow key={result.model+result.id}>
                  <StyledTableCell>{result.model}</StyledTableCell>
                  <StyledTableCell>{result.brand}</StyledTableCell>
                  <StyledTableCell>{result.os}</StyledTableCell>
                  <StyledTableCell>{result.size}</StyledTableCell>
                  <StyledTableCell>{result.price}</StyledTableCell>
                  <TableCell>
                  <Button variant="outlined" color="success" onClick={()=>handleViewClick(result)} >
                    details
                  </Button>
                    
                  </TableCell>
                  {/* Add more cells as needed */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedAndFilteredResults.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}



        {/* Full-page Dialog */}
        <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {rowData && rowData.model}
            </Typography>
            
          </Toolbar>
        </AppBar>

        <DialogContent>
          {/* Content for the full-page dialog */}

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            
            {modelData[0] && (
            <Card sx={{padding:2}}>
              <CardContent>
               <Typography sx={{ fontSize: 22}} style={{ color: '#01579b' }}>{modelData[0].model}</Typography>
              </CardContent>
              <Table>
              <TableHead>
                <StyledTableRow>
                  <TableCell>Property</TableCell>
                  <TableCell>Value</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                  <TableCell>Brand</TableCell>
                  <TableCell> {modelData[0].brand}</TableCell>
              </TableRow>
              
              <TableRow>
                  <TableCell>Link</TableCell>
                  <TableCell> 
                    <Link underline='always'
                    onClick={() => {
                      console.info("I'm a button.");
                      window.open(modelData[0].hyperlink, '_blank');
                    }}
                    >
                    {modelData[0].hyperlink}
                    </Link>
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Size</TableCell>
                  <TableCell> {modelData[0].size}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>OS</TableCell>
                  <TableCell> {modelData[0].os}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>RAM</TableCell>
                  <TableCell> {modelData[0].ram}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>ROM</TableCell>
                  <TableCell> {modelData[0].rom}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>CPU</TableCell>
                  <TableCell> {modelData[0].CPU}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>GPU</TableCell>
                  <TableCell> {modelData[0].GPU}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Warrenty</TableCell>
                  <TableCell> {modelData[0].warranty}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Resolution</TableCell>
                  <TableCell> {modelData[0].resolution}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Backlight</TableCell>
                  <TableCell> {modelData[0].backlight}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Brightness</TableCell>
                  <TableCell> {modelData[0].brand}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Contrast</TableCell>
                  <TableCell> {modelData[0].brightness}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Audio</TableCell>
                  <TableCell> {modelData[0].audio}</TableCell>
              </TableRow>

              </TableBody>
            </Table>
              
            </Card>
          )}
            
          </Grid>
          <Grid item xs={6}>
          <Typography sx={{margin:1}} fontSize={22} >
           Price List Chart
          </Typography> 
              {
                 modelData && <MultiLineChart data= {modelData}  />
              }
         <Typography sx={{margin:1}} fontSize={22}>
           Price List History
          </Typography> 
              {
                modelData && (
                  <TableContainer component={Paper} sx={{margin:2}}>
                    <Table>
                      <TableHead>
                        <StyledTableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Seller</TableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {modelData.map(row => (
                          <TableRow>
                          <TableCell>{format(new Date(row.createdAt), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                          <TableCell>{row.price}</TableCell>
                          <TableCell>{row.seller}</TableCell>
                        </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                )
              }

          </Grid>
        
        </Grid>
          
        </DialogContent>
       
      </Dialog>


      {/* Full-page Dialog */}
      <Dialog
        fullScreen
        open={openM}
        onClose={handleMClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleMClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
               TV data price compare
            </Typography>
            
          </Toolbar>
        </AppBar>

        <DialogContent>
          {/* Content for the full-page dialog */}

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={10} >  
              {
                 comapreData && 
                  <CompareChart  tvData={comapreData}  />
                
              }
          </Grid>
        
        </Grid>
          
        </DialogContent>
       
      </Dialog>

        </div>
      );
}

export default Search;