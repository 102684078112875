import "chart.js/auto";
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';

export default function MultiLineChart({data}) {

  const chartData = data.map(item => ({
    x:  format(new Date(item.createdAt), 'dd-MM-yyyy'), 
    y: parseInt(item.price), 
    seller: item.seller,
  }));

  // Organize data based on unique sellers
  const sellers = Array.from(new Set(data.map(item => item.seller)));
  const chartDatasets = sellers.map(seller => {
    const sellerData = chartData
      .filter(item => item.seller === seller)
      .map(item => ({ x: item.x, y: item.y }));

    return {
      label: seller,
      data: sellerData,
      borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
      fill: false,
      stepped: 'before',
    };
  });

  console.log(chartDatasets);

  const options = {
    scales: {
      x: [
        {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MMM D',
            },
          },
          scaleLabel: {
            display: true,
            labelString: 'Date',
          },
        },
      ],
      y: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Price',
          },
        },
      ],
    },
    legend: {
      align: 'start', // Set the alignment to 'start' for left placement
      position: 'left',
    },

    // Use the adapters property to specify the date adapter
    adapters: {
      date: {
        format: (value) => format(value, 'dd-MM-yyyy'),
      },
    },
  };


  const chartDataObject = {
    datasets: chartDatasets,
  };

  return (
    <Line data={chartDataObject} options={options} />
  );
};
