import "chart.js/auto";
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';

export default function CompareChart({ tvData }) {
  // Check if tvData is defined and has at least one model
  if (!tvData || tvData.length === 0 || !tvData[0]) {
    return <p>No data available for chart</p>;
  }

  const chartData = {
    labels: tvData.map((entry) => format(new Date(entry[0].createdAt), 'dd-MM-yyyy')),
    datasets: tvData.map((model, index) => ({
      label: `${model[0].brand} ${model[0].model}`,
      data: model.map((entry) => parseFloat(entry.price)),
      fill: false,
      stepped: 'before',
      borderColor: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)})`,
    })),
  };

  const options = {
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MMM D',
            },
          },
         
        }
      ],
      yAxis: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Price',
          },
        },
      ],
    },
  
    plugins: {
      legend: {
        display: true,
        align: 'left', // Change this to 'left' to display labels on the left side
        position: 'right',
        labels: {
          boxWidth: 25, // Adjust this value to control label spacing
        },
      },
    },
    // Use the adapters property to specify the date adapter
    adapters: {
      date: {
        format: (value) => format(value, 'dd-MM-yyyy'),
      },
    },
  };

  return (
    <div>
      <Line data={chartData} options={options} />
    </div>
  );
};
